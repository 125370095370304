import { Checkbox, Form, Input } from "antd";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import Image from "../assets/img/Images";
import { toggleMenu } from "../redux/actions";
import { logInUser } from "../redux/actions/index";

function LoginForm(props) {
  const [email, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const logUserIn = async (e) => {
    e.preventDefault();
    await props.logInUser(email, password);
  };
  const [form] = Form.useForm();
  function onChange(value) {}

  return (
    <div>
      {props.toggleReducer && (
        <div className="position-relative">
          <div className="container-fluid animated fadeIn">
            <div className="menu-bar ">
              <div className="overlay"></div>
              <div className="menu animated slideInLeft">
                <div className="h-100vh flex dir-column justify-center login">
                  <div className="position-relative">
                    <h2 className="login-title mb-3">Giriş</h2>
                    <div className="customForm col-4">
                      <Form layout={"vertical"} form={form}>
                        <Form.Item className="login-label">
                          <Input
                            className="login-input"
                            value={email}
                            onChange={(e) => setUsername(e.target.value)}
                            type="text"
                            placeholder="Email"
                          />
                        </Form.Item>
                        <Form.Item className="login-label">
                          <Input.Password
                            className="login-input"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                            placeholder="Şifrə"
                          />
                        </Form.Item>
                        <Form.Item name="remember">
                          <Checkbox className="login-title" checked={true}>
                            {" "}
                            Yadda saxla
                          </Checkbox>
                        </Form.Item>
                        <div className={"ml-15 animated fadeIn mt-15"}>
                          <ReCAPTCHA
                            className={"w-100 my-3"}
                            sitekey="6LcQk4waAAAAAAxiShlf-3FWHtgKSp13jHfiZj0T"
                            onChange={onChange}
                          />
                        </div>
                        <Form.Item>
                          <div className="flex">
                            <button onClick={logUserIn} className="button mt-3">
                              Daxil ol
                            </button>
                          </div>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hex-menu">
                <img src={Image.Hex} alt="" />
              </div>
              <div className=" menu-list-map">
                <img src={Image.Map} alt="" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = ({ user, toggleReducer }) => {
  return {
    loggedIn: user.isLoggedIn,
    message: user.message,
    notify: user.notify,
    toggleReducer,
  };
};

export default connect(mapStateToProps, { toggleMenu, logInUser })(LoginForm);
