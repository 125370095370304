import {
  AuditOutlined,
  BookOutlined,
  ContactsOutlined,
  ExceptionOutlined,
  FileTextOutlined,
  HomeOutlined,
  OrderedListOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../../assets/css/global/global.css";
import "../../assets/css/override/override.css";
import { logOut } from "../../redux/actions";
import history from "../../utils/const/history";
const MenuList = (props) => {
  const [openKeys, setOpenKeys] = useState([]);
  const rootSubmenuKeys = ["10", "50", "21", "31", "41", "51", "61"];

  const logOut = () => {
    localStorage.removeItem("access_token");
    props.logOut();
    history.push("/");
  };

  useEffect(() => {
    let exp = localStorage.getItem("exp");
    let logtime = localStorage.getItem("now");
    let expDate = parseInt(logtime) + parseInt(exp);
    let time0ut = expDate - Date.now();
    if (time0ut <= 0) {
      logOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Date.now()]);

  const onOpenChange = (openKeysList) => {
    const latestOpenKey = openKeysList.find(
      (key) => openKeys.indexOf(key) === -1
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeysList);
    } else {
      const opens = latestOpenKey ? [latestOpenKey] : [];
      setOpenKeys(opens);
    }
  };

  return (
    <Menu
      openKeys={openKeys}
      mode="inline"
      theme="light"
      onOpenChange={onOpenChange}
      className="menu-ul"
    >
      <Menu.Item key="about">
        <HomeOutlined />
        <Link to={`/about`}>
          <span>Haqqımızda</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="certificates">
        <BookOutlined />
        <Link to={`/certificates`}>
          <span>Sertifikatlar</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="catalogs">
        <FileTextOutlined />
        <Link to={`/catalogs`}>
          <span>Kataloqlar</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="contact">
        <ContactsOutlined />
        <Link to={`/contact`}>
          <span>Əlaqə</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="reviews">
        <ContactsOutlined />
        <Link to={`/reviews`}>
          <span>Müştəri rəyləri</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="partners">
        <UserOutlined />
        <Link to={`/partners`}>
          <span>Partnyorlar</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="portfolios">
        <AuditOutlined />
        <Link to={`/portfolios`}>
          <span>Portfolio</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="news">
        <OrderedListOutlined />
        <Link to={`/news`}>
          <span>Xəbərlər</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="services">
        <ExceptionOutlined />
        <Link to={`/services`}>
          <span>Xidmətlər</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="teams">
        <TeamOutlined />
        <Link to={`/teams`}>
          <span>Komanda</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="vacancies">
        <TeamOutlined />
        <Link to={`/vacancies`}>
          <span>Vakansiyalar</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="applicants">
        <TeamOutlined />
        <Link to={`/applicants`}>
          <span>Vakansiyaya müraciətlər</span>
        </Link>
      </Menu.Item>
    </Menu>
  );
};

const mapStateToProps = ({ edit }) => {
  return { edit };
};

export default connect(mapStateToProps, { logOut })(MenuList);
