import { message } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Image from "../../../assets/img/Images";
import { logInUser, toggleMenu } from "../../../redux/actions/index";
import LoginForm from "../../LoginForm";

const Login = (props) => {
  const { toggleMenu } = props;
  useEffect(() => {
    if (props.message.trim().length !== 0) {
      message.warning(props.message);
    }
  }, [props.message, props.notify]);

  return (
    <>
      <div className="container-fluid">
        <div className="row align-items-center header header-fixed m-0">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6">
            <Link to="/">
              <img src={Image.Logo} alt="" className="logo-main" />
            </Link>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-end align-items-center pt-0">
            <div className="ml-lg-5 cursor-pointer ml-md-4 ml-sm-3 ml-3">
              {props.toggleReducer ? (
                <img onClick={toggleMenu} src={Image.MenuClose} alt="" />
              ) : (
                <button
                  onClick={toggleMenu}
                  className="shadow btm-warning input-btn"
                >
                  Daxil ol
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="main-login">
        {!props.toggleReducer && (
          <div className="position-relative">
            <div className="container-fluid">
              <div className="main-section col-lg-4 col-md-4 col-sm-8 col-8 p-0">
                <div className="main-text">
                  <p>Dayandığınız nöqtə dünyanın mərkəzidir!</p>
                </div>
                <div>
                  <img src={Image.VirtualAzerbaijan} alt="" />
                </div>
                <div>
                  <span>
                    Biz sizə rəqəmsəl dünyaya acılan qapılar inşa edirik. Şəxsi
                    məlumatlarınızın güvənli olması üçün hökumətlə olan iş
                    birliyimiz innovativ ideya və texnologiyalarla davam edir.
                  </span>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-4 col-4">
                <div className="brain">
                  <img src={Image.Brain} alt="" />
                </div>
              </div>
            </div>
            <div className="map">
              <img src={Image.Map} alt="" />
            </div>
          </div>
        )}
      </div>
      <div className="d-lg-flex d-md-flex d-sm-block d-block align-items-center justify-content-between footer">
        <div>
          <span>
            © {new Date().getFullYear()} Virtual Azerbaijan. - All Rights
            Reserved.
          </span>
        </div>
        <div className="social-icon">
          <ul className="d-flex p-0">
            <Link to="/">
              <li>
                <img src={Image.Facebook} alt="" />
              </li>
            </Link>
            <Link to="/">
              <li>
                <img src={Image.Instagram} alt="" />
              </li>
            </Link>
            <Link to="/">
              <li>
                <img src={Image.Twitter} alt="" />
              </li>
            </Link>
            <Link to="/">
              <li>
                <img src={Image.Linkedin} alt="" />
              </li>
            </Link>
            <Link to="/">
              <li>
                <img src={Image.Vkontakte} alt="" />
              </li>
            </Link>
            <Link to="/">
              <li>
                <img src={Image.Youtube} alt="" />
              </li>
            </Link>
          </ul>
        </div>
      </div>
      <LoginForm />
    </>
  );
};
const mapStateToProps = ({ user, toggleReducer }) => {
  return {
    loggedIn: user.isLoggedIn,
    message: user.message,
    notify: user.notify,
    toggleReducer,
  };
};

export default connect(mapStateToProps, { logInUser, toggleMenu })(Login);
