import types from "./../types";

let a = localStorage.getItem("access_token");
const initialUser = {
  isLoggedIn: a ? true : false,
  data: {
    role: 5,
  },
  message: "",
  notify: true,
};

export function userReducer(userData = initialUser, action) {
  switch (action.type) {
    case types.GET_USER:
      return userData;
    case types.SET_USER_LOGGED_IN:
      let data = {
        name: action.payload.name,
        userId: action.payload.id,
        email: action.payload.email,
      };
      return {
        data,
        isLoggedIn: true,
        message: "",
        notify: !userData.notify,
      };
    case types.SET_USER:
      return {
        data: action.payload.data,
        isLoggedIn: true,
        message: "Successfully logged in",
        notify: !userData.notify,
      };
    case types.SET_USER_ERROR:
      return {
        ...userData,
        message: action.payload.message,
        notify: !userData.notify,
      };
    case types.LOG_OUT:
      return {
        notify: userData.notify,
        message: "",
        data: {},
        isLoggedIn: false,
      };
    default:
      return userData;
  }
}

export function loaderReducer(isLoading = 0, action) {
  switch (action.type) {
    case types.LOADING_ON:
      return ++isLoading;
    case types.LOADING_OFF:
      return isLoading === 0 ? 0 : --isLoading;
    default:
      return isLoading;
  }
}

export function langReducer(
  lang = localStorage.getItem("locale") || "az",
  action
) {
  switch (action.type) {
    case types.SET_LANG:
      return action.payload;
    case types.GET_LANG:
      return lang;
    default:
      return lang;
  }
}

export function notificationReducer(
  data = { description: "", isHappy: true, notify: false },
  action
) {
  switch (action.type) {
    case types.SET_NOTIFICATION:
      let newData = { ...action.payload, notify: !data.notify };
      return newData;
    default:
      return data;
  }
}

export const toggleReducer = (data = false, action) => {
  switch (action.type) {
    case types.TOGGLE:
      return !data;
    default:
      return data;
  }
};

export const titleReducer = (data = "", action) => {
  switch (action.type) {
    case types.SET_TITLE:
      return action.payload;
    default:
      return data;
  }
};

export const dataReducer = (data = {}, action) => {
  switch (action.type) {
    case types.SET_DATA:
      const new_data = { ...data };
      new_data[action.payload.page] = action.payload.data;
      return new_data;
    default:
      return data;
  }
};
