import Brain from "./brain.svg";
import MenuClose from "./close.png";
import Facebook from "./facebook.svg";
import Instagram from "./instagram.svg";
import Linkedin from "./linkedin.svg";
import list1 from "./list1.png";
import list2 from "./list2.png";
import Logo from "./logo.svg";
import Map from "./map.svg";
import MenuOpen from "./menu-icon.svg";
import Play from "./play.svg";
import Twitter from "./twitter.svg";
import VirtualAzerbaijan from "./virtualaze.svg";
import Vkontakte from "./vk.svg";
import Youtube from "./youtube.svg";

const def = {
  Logo,
  VirtualAzerbaijan,
  Play,
  Map,
  Brain,
  MenuOpen,
  MenuClose,
  Facebook,
  Instagram,
  Twitter,
  Linkedin,
  Vkontakte,
  Youtube,
  list1,
  list2,
};
export default def;
