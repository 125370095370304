import {
  CheckOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Layout, notification } from "antd";
import React from "react";
import { connect } from "react-redux";
import { Link, Route, Switch } from "react-router-dom";
import "./all.css";
import logo from "./assets/img/logo.svg";
import BottomMenu from "./components/Elements/BottomMenu";
import MenuList from "./components/Elements/MenuList";
import Login from "./components/Layout/Login/Login";
import TopMenu from "./components/Layout/TopMenu";
import About from "./pages/about/About";
import Applicants from "./pages/applicants/Applicants";
import Catalogs from "./pages/catalogs/Catalogs";
import Certificates from "./pages/certificates/Certificates";
import Contact from "./pages/contact/Contact";
import News from "./pages/news/News";
import Partners from "./pages/partners/Partners";
import Portfolios from "./pages/portfolios/Portfolios";
import Reviews from "./pages//reviews/Reviews";  
import Services from "./pages/services/Services";
import Team from "./pages/team/Team";
import Vacancies from "./pages/vacancies/Vacancies";
import { logInUser } from "./redux/actions";
import { titleReducer } from "./redux/reducers";
import history from "./utils/const/history";

const { Content, Sider } = Layout;

class App extends React.Component {
  state = {
    collapsed: window.innerWidth < 1200,
    web: true,
    ismap: false,
    stocks: null,
    isWhite: true,
    cats: [],
  };

  toggleButtons = () => {
    const className = `flex sider-btn ${
      this.state.collapsed ? "all-center" : "flex-between open"
    }`;
    return (
      <div className={className}>
        {!this.state.collapsed ? (
          <Link to="/">
            {" "}
            <img src={logo} alt="" />{" "}
          </Link>
        ) : null}
        <Button type="primary menu-btn" onClick={this.onCollapse}>
          {this.state.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </div>
    );
  };

  onCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
    this.setState({ web: window.innerWidth > 1200 });
  };

  componentDidMount() {
    localStorage.setItem("locale", "az");
    this.setState({ web: window.innerWidth > 1200 });
    window.addEventListener("resize", () => {
      this.setState({
        web: window.innerWidth > 1200,
        collapsed: window.innerWidth < 1200,
      });
    });
    this.setState({
      ismap: window.location.pathname === "/",
      isWhite: window.location.pathname === "/",
    });
    history.listen((location) => {
      this.setState({
        ismap: location.pathname === "/",
        isWhite: location.pathname === "/",
      });
    });
  }

  componentDidUpdate(prevProps) {
    const prev = prevProps.notification;
    const curr = this.props.notification;
    if (prev.notify !== curr.notify) {
      let desc = !curr.isHappy
        ? curr.description?.status === 400
          ? curr.description.data.error
          : this.props.t("errorMessage")
        : curr.description;
      notification.info({
        message: curr.isHappy
          ? this.props.t("successMessage")
          : this.props.t("errMessage"),
        description: desc.length ? desc : null,
        icon: curr.isHappy ? <CheckOutlined /> : <WarningOutlined />,
      });
    }
  }

  render() {
    return (
      <>
        {this.props.isLoggedIn ? (
          <div id="page">
            <Layout className="letside">
              {this.state.web ? (
                <Sider
                  className="side-menu animated slideInLeft"
                  style={{ backgroundColor: "white" }}
                  collapsed={this.state.collapsed}
                  collapsedWidth={80}
                  onCollapse={this.onCollapse}
                  width={250}
                >
                  {this.toggleButtons()}
                  <MenuList collapsed={this.state.collapsed} />
                </Sider>
              ) : (
                <Drawer
                  className="drawer"
                  width={320}
                  title={this.toggleButtons()}
                  placement="left"
                  closable={false}
                  onClose={this.onCollapse}
                  visible={!this.state.collapsed}
                  key="key"
                >
                  <MenuList collapsed={this.state.collapsed} />
                  <BottomMenu />
                </Drawer>
              )}
              <Layout
                className={
                  this.state.collapsed ? "collapsedRight" : "nonCollapsedRight"
                }
              >
                <TopMenu
                  toggleDrawer={this.onCollapse}
                  showDrawerButton={!this.state.web}
                  collapsed={this.state.collapsed}
                  props={window.location.pathname}
                  title={titleReducer}
                />
                <Content>
                  <div className={`page-routes`}>
                    <Switch>
                      <Route exact path="/about" component={About} />
                      <Route exact path="/partners" component={Partners} />
                      <Route exact path="/contact" component={Contact} />
                      <Route exact path="/reviews" component={Reviews} />
                      <Route exact path="/catalogs" component={Catalogs} />
                      <Route exact path="/services" component={Services} />
                      <Route exact path="/news" component={News} />
                      <Route exact path="/portfolios" component={Portfolios} />
                      <Route
                        exact
                        path="/certificates"
                        component={Certificates}
                      />
                      <Route exact path="/teams" component={Team} />
                      <Route exact path="/vacancies" component={Vacancies} />
                      <Route exact path="/applicants" component={Applicants} />
                      <Route exact path="/" component={About} />
                      <Route path="**">
                        <p className="flex all-center h-100vh">Not found</p>
                      </Route>
                    </Switch>
                  </div>
                </Content>
              </Layout>
            </Layout>
          </div>
        ) : (
          <>
            <Switch>
              <Route exact path="/" component={Login} />
            </Switch>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ user, loader, notification }) => {
  return {
    isLoggedIn: user.isLoggedIn,
    isLoading: loader,
    notification,
  };
};

export default connect(mapStateToProps, { logInUser })(App);
