import { Button, Table, Tooltip, Popconfirm, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setTitle } from "../../redux/actions";
import admin from "../../utils/const/api";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { convertColumns } from "../../utils/columnconverter";

const Applicants = (props) => {
  const mainUrl = "admin/vacancy/applies";
  const [postlist, setPostList] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [id, setId] = useState(null);

  const [spin, setSpin] = useState(false);

  useEffect(() => {
    getPostList();
    props.setTitle("Vakansiyaya müraciətlər");
  }, []);

  const cols = [
    { key: "name", con: true },
    { key: "phone", con: true },
    { key: "email", con: true },
    { key: "body", con: true },
  ];

  const initialColumns = [
    {
      title: "Adı",
      dataIndex: "name",
      key: "1",
    },
    {
      title: "Əlaqə nömrəsi",
      dataIndex: "phone",
      key: "2",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "3",
    },
    {
      title: "Vakansiyaya müraciətlər",
      dataIndex: "cv_uuid",
      key: "4",
      render: (i) => {
        console.log(i)
        
        return (
          <div style={{cursor:"pointer"}}>
            <p onClick={() => handleDownloadCV(i)}>
              Download CV
            </p>
          </div>
        );
      },
    },
  ];
  const getPostList = (obj) => {
    setSpin(true);
    admin.get(mainUrl, { params: obj }).then((res) => {
      console.log(res.data?.data?.data);

      res.data?.data?.data && setSpin(false);
      setPostList(
        res.data?.data?.data.map((d, index) => {
          return {
            ...d,
            key: index + 1,
            index,
          };
        })
      );
    });
  };

  const handleDownloadCV = async (cv_uuid) => {
    try {
      // CV'yi indirme isteği
      const response = await admin.get(`admin/file/downloads/${cv_uuid}`, {
        responseType: "blob", // Binary response
      });

      // Dosyayı indir
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "cv.pdf"); // İndirilen dosyanın adı
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("CV download error:", error);
    }
  };
  return (
    <>
  

      <Table
        size="small"
        className="bg-white animated fadeIn"
        columns={initialColumns}
        dataSource={convertColumns(postlist, cols)}
        pagination={{
          pageSize: 25,
          current_page: 1,
        }}
      />

      {/* <Modal
        centered
        className="addTaskModal padModal"
        visible={visibleModal}
        onOk={() => setVisibleModal(false)}
        onCancel={() => setVisibleModal(false)}
        footer={[]}
      >
        <Update
            id={id}
           setVisibleModal={setVisibleModal}
        />
      </Modal> */}
    </>
  );
};

const mapStateToProps = ({ titleReducer }) => {
  return {
    titleReducer,
  };
};
export default connect(mapStateToProps, { setTitle })(Applicants);
