import { Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import az from "../../assets/img/az.svg";
import ru from "../../assets/img/ru.svg";
import en from "../../assets/img/us.svg";
import { changeLanguage } from "../../redux/actions";

const BottomMenu = (props) => {
  const [lang, setLang] = useState("az");
  const [flag, setFlag] = useState(az);

  const changeLang = (lang) => {
    const { i18n } = props;
    i18n.changeLanguage(lang);
    localStorage.setItem("locale", lang);
    props.changeLanguage(lang);
    setLang(lang);
  };

  useEffect(() => {
    setLang(
      localStorage.getItem("locale") ? localStorage.getItem("locale") : "az"
    );
    switch (lang) {
      case "az":
        setFlag(az);
        break;
      case "en":
        setFlag(en);
        break;
      case "ru":
        setFlag(ru);
        break;
      default:
        setFlag(az);
        break;
    }
  }, [lang]);

  const menu = (
    <Menu>
      <div className="pl-1">
        <p>Dil seçimi</p>
      </div>
      <Menu.Item key="az" onClick={() => changeLang("az")}>
        <div className="flex">
          <span className="mr5-10">
            <img src={az} alt="" />
          </span>
          <span>Azərbaycanca</span>
        </div>
      </Menu.Item>
      <Menu.Item key="en" onClick={() => changeLang("en")}>
        <div className="flex">
          <span className="mr5-10">
            <img src={en} alt="" />
          </span>
          <span>English</span>
        </div>
      </Menu.Item>
      <Menu.Item key="ru" onClick={() => changeLang("ru")}>
        <div className="flex">
          <span className="mr5-10">
            <img src={ru} alt="" />
          </span>
          <span>Русский</span>
        </div>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="bottom-menu border-top">
      <div>
        <Dropdown overlay={menu} placement="topLeft">
          <img src={flag} alt="" />
        </Dropdown>
      </div>
    </div>
  );
};

export default connect(null, { changeLanguage })(withTranslation()(BottomMenu));
