import { Button, Table, Tooltip, Popconfirm, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setTitle } from "../../redux/actions";
import admin from "../../utils/const/api";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { convertColumns } from "../../utils/columnconverter";


const Contact = (props) => {
  const mainUrl = "admin/contact/us?per_page=5234";
  const [postlist, setPostList] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [id,setId]=useState(null)

  const [spin, setSpin] = useState(false);
 


  useEffect(() => {
    getPostList();
    props.setTitle("Bizimlə əlaqə");
  }, []);



  const cols = [
    { key: "name", con: true },
    { key: "email", con: true },
    { key: "body", con: true },
  ];

  const initialColumns = [
    {
      title: "Adı",
      dataIndex: "name",
      key: "1",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "2",
    },  {
      title: "Mətn",
      dataIndex: "body",
      key: "3",
    },
    
  ];
  const getPostList = (obj) => {
    setSpin(true);
    admin.get(mainUrl, { params: obj }).then((res) => {
      console.log(res.data?.data?.data)

      res.data?.data?.data && setSpin(false);
      setPostList(
        res.data?.data?.data.map((d, index) => {
          return {
            ...d,
            key: index + 1,
            index
           
          };
        })
      );
    });
  };
  return (
    <>
      
      <Table
        size="small"
        className="bg-white animated fadeIn"
        columns={initialColumns}
        dataSource={convertColumns(postlist, cols)}
        pagination={{
          pageSize: 25,
          current_page: 1,
        }}
      />

      {/* <Modal
        centered
        className="addTaskModal padModal"
        visible={visibleModal}
        onOk={() => setVisibleModal(false)}
        onCancel={() => setVisibleModal(false)}
        footer={[]}
      >
        <Update
            id={id}
           setVisibleModal={setVisibleModal}
        />
      </Modal> */}
    </>
  );
};

const mapStateToProps = ({ titleReducer }) => {
  return {
    titleReducer,
  };
};
export default connect(mapStateToProps, { setTitle })(Contact);
