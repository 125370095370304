import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import './assets/css/style.css';
import './assets/css/animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import { BrowserRouter as Router } from "react-router-dom";
import history from "./utils/const/history";
import store from './redux/store';
import { Provider } from 'react-redux';

ReactDOM.render(<Provider store={store}><Router history={history}><App /></Router></Provider>,  document.getElementById("root"));
