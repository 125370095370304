import { Button, Table, Tooltip, Popconfirm, Modal,message } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setTitle } from "../../redux/actions";
import admin from "../../utils/const/api";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { convertColumns } from "../../utils/columnconverter";
import Update from "./update";

const Vacancies= (props) => {
  const mainUrl = "admin/vacancies";
  const catalogUrl="admin/catalogs?per_page=631342"
  const currencieUrl="admin/currencies"

  const [postlist, setPostList] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);

  const [id, setId] = useState(null);
  const [spin,setSpin] =useState(false)

  const[catalogs,setCatalogs]=useState([])
  const [currencies,setCurrencies]=useState([])

  useEffect(() => {
    getPostList();
    catalogList()
    currenciesList()
    props.setTitle("Vakansiyalar");
  }, []);

  const cols = [
    { key: "title", con: true },
    { key: "description", con: true },
    { key: "qualification", con: true },
    { key: "level", con: true },
    { key: "salary", con: true },
    { key: "id", con: true },
  ];

  const initialColumns = [
  
    {
      title: "Başlıq",
      dataIndex: "title",
      key: "1",
    },
    {
      title: "Qısa izah",
      dataIndex: "description",
      key: "2",
    },
    {
      title: "İxtisaslaşma",
      dataIndex: "qualification",
      key: "3",
    },
    {
      title: "İxtisaslaşma səviyyəsi",
      dataIndex: "level",
      key: "4",
    },
    {
      title: "Maaş",
      dataIndex: "salary",
      key: "5",
    },

    {
      title: "Əməliyyatlar",
      dataIndex: "id",
      key: "id",
      render: (i, element) => {
        return (
          <div className="flex flex-start">
            <Tooltip placement="topRight" className="ml-5" title={"Düzəliş et"}>
              <Button
                className="border-none mr-5 d-flex align-items-center"
                type="primary"
                onClick={() => {
                  setVisibleModal(true);
                  setId(i);
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>

            <Popconfirm
              placement="topRight"
              title={"Are you sure to delete this item?"}
              onConfirm={() => deleteList(i)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip placement="topRight" className="ml-5" title={"Sil"}>
                <Button
                  className="border-none d-flex align-items-center"
                  type="danger"
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const getPostList = (obj) => {
    setSpin(true);
    admin.get(mainUrl).then((res) => {
      res.data?.data?.data && setSpin(false);
      setPostList(
        res.data?.data?.data.map((d, index) => {
          return {
            ...d,
            key: index + 1,
            index,
            title: d?.locale?.title,
            description: cleanHtmlTags(d?.locale?.short_description),
            qualification:d?.locale?.qualification,
            level:d?.locale?.level
          };
        })
      );
    });
  };

  const cleanHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || "";
  };

  const catalogList=()=>{
    admin.get(catalogUrl).then((res)=>{
    
      setCatalogs(res.data?.data?.data)

    })
  }
  
  const currenciesList=()=>{
    admin.get(currencieUrl).then((res)=>{
    
    
      setCurrencies(res.data?.data)

    })
  }
  
console.log(currencies)
  const deleteList = (i) => {
    if (i) {
      admin
        .delete(`${mainUrl}/${i}`)
        .then((res) => {
          message.success("Item was successfully deleted");
          getPostList();
        })
        .catch((err) => {
          // notify(err.response, false);
        });
    }
  };

  return (
    <>
      <Button
        className="add-btn"
        onClick={() => {
          setVisibleModal(true);
          setId(null);
        }}
      >
        Əlavə et
      </Button>

      <Table
        size="small"
        className="bg-white animated fadeIn"
        columns={initialColumns}
        dataSource={convertColumns(postlist, cols)}
        pagination={{
          pageSize: 25,
          current_page: 1,
        }}
      />

      <Modal
        centered
        className="addTaskModal padModal"
        visible={visibleModal}
        onOk={() => setVisibleModal(false)}
        onCancel={() => setVisibleModal(false)}
        footer={[]}
      >
        <Update
          id={id}
          setVisibleModal={setVisibleModal}
          getPostList={getPostList}
          catalogs={catalogs}
          currencies={currencies}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = ({ titleReducer }) => {
  return {
    titleReducer,
  };
};
export default connect(mapStateToProps, { setTitle })(Vacancies);
