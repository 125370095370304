import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Select,
  Input,
  Button,
  Form,
  Collapse,
  Upload,
  message,
  Modal,
} from "antd";

import JoditEditor from "jodit-react";
import admin from "../../utils/const/api";

const { TextArea } = Input;
const { Panel } = Collapse;

const { Option } = Select;
function Update({ id, setVisibleModal, getPostList, catalogs }) {
  const mainUrl = "admin/our/teams";

  const [form] = Form.useForm();

  const editor = React.createRef();
  const [fileList, setFileList] = useState([]);
  const [media, setMedia] = useState();
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [about, setAbout] = useState({});

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length <= 0) {
      setMedia(null);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  const uploadFile = ({ onSuccess, onError, file }) => {
    let form_data = new FormData();
    const filename = Math.random(1, 999999) + Date.now() + file.name;
    form_data.append("image", file, filename);
    admin
      .post("admin/file/uploads/single/image", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        // handleImage(res.data.data.image_uuid, form_key);
        setMedia(res?.data?.data?.image_uuid);
        onSuccess(null, file);
      })
      .catch((err) => onError());
  };

  const langs = [
    {
      name: "Azərbaycan dili",
      key: "az",
      id: 1,
    },
    {
      name: "İngilis dili",
      key: "en",
      id: 2,
    },
    {
      name: "Rus dili",
      key: "ru",
      id: 3,
    },
  ];
  useEffect(() => {
    form.resetFields();
    setFileList([]);
    if (id) {
      let obj = {};
      let datas = {};
      admin.get(`${mainUrl}/${id}`).then((res) => {
        setFileList(
          res?.data?.data?.file !== null
            ? [
                {
                  uid: res.data.data.file,
                  url: res.data.data.file.file,
                },
              ]
            : []
        );
        setMedia(res.data.data.file.uuid);
        res?.data?.data?.locales.forEach((d) => {
          obj[`name_${d.locale}`] = d.name;
          // obj[`title_${d.locale}`] = d.title;
          obj[`body_${d.locale}`] = d.description;
          datas[`body_${d.locale}`] = d.description;
        });
        obj[`catalog_id`] = res.data.data.catalog_id;

        setAbout(datas);
        if (form.current !== null) {
          form.setFieldsValue(obj);
        }
      });
    }
  }, [id]);

  const savePosition = async (values) => {
    const langs = ["az", "en", "ru"];
    let obj = {
      locales: langs.map((lang, index) => {
        return {
          locale: lang,
          name: values[`name_${lang}`],
          // title: values[`title_${lang}`],
          description: values[`body_${lang}`],
        };
      }),

      profile_uuid: media,
      catalog_id: values[`catalog_id`],
    };

    await checkUndefinedValues(values);
    if (!id) {
      await admin
        .post(mainUrl, obj)
        .then((res) => {
          // notify("", true);
          // getPositions();
          // cancelEditing();
          message.success("Item was successfully saved");
          setVisibleModal(false);
          getPostList();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await admin
        .put(`${mainUrl}/${id}`, obj)
        .then((res) => {
          // notify("", true);
          // getPositions();
          // cancelEditing();
          message.success("Item was successfully saved");
          setVisibleModal(false);
          getPostList();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  async function checkUndefinedValues(obj) {
    const fields = Object.values(obj);
    const fieldsUndefined = fields.some(
      (value) => value === null || value === "" || value === undefined || media===null
    );
    if (fieldsUndefined) {
      message.error("Please fill in all fields.");
    }
    console.log(fields);
  }
  return (
    <div>
      <Form form={form} onFinish={savePosition}>
        <div>
          <Row>
            <div className="flex gap-5">
              <Col xs={12}>
                <p className={"mb-15"}>Foto</p>
                <Form.Item validateTrigger="onChange" required>
                  <Upload
                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    beforeUpload={beforeUpload}
                    customRequest={uploadFile}
                    onPreview={handlePreview}
                  >
                    {fileList.length < 1 && "+ Yüklə"}
                  </Upload>
                </Form.Item>
              </Col>

              <Col xs={24}>
                <p className={"mb-15"}>Kataloq</p>
                <Form.Item
                  name={"catalog_id"}
                  validateTrigger="onChange"
                  onFinsh={savePosition}
                >
                  <Select
                    showSearch
                    className="mb-4"
                    notFoundContent={null}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {catalogs.map((item, i) => {
                      return (
                        <Option key={i} value={item.id}>
                          {item.locale.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </div>

            <Collapse defaultActiveKey={[1]}>
              {langs.map((l, index) => (
                <Panel header={l.name} key={l.id} forceRender={true}>
                  <div className="flex gap-4">
                    <Col md={6} sm={12} xs={24}>
                      <p className={"mb-15"}>Adı</p>

                      <div key={l.id} className="form-lang">
                        <Form.Item
                          validateTrigger="onChange"
                          name={`name_${l.key}`}
                          // rules={[noWhitespace(t("inputError"))]}
                        >
                          <Input className="w-100" />
                        </Form.Item>
                        <span className="input-lang ">{l.key}</span>
                      </div>
                    </Col>
                  </div>

                  <Col xs={24}>
                    <div key={l.id} className="form-lang">
                      <div className="mb-10">Qısa izah</div>
                      <Form.Item
                        name={`body_${l.key}`}
                        validateTrigger="onChange"
                        // getValueFromEvent={(event, editor) => {
                        //     const dat = editor.getData();
                        //     return dat;
                        // }}
                        // rules={[noWhitespace(t("inputError"))]}
                      >
                        <JoditEditor
                          ref={editor}
                          //   value={this.state.aboutt[`about_${l.key}`]}
                          value={about[`body_${l.key}`]}
                          //   tabIndex={1} // tabIndex of textarea
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Panel>
              ))}
            </Collapse>

            <Col span={24}>
              <Button
                className="fr mt-3"
                htmlType="submit"
                type="primary"
                // onClick={onSubmit}
              >
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      <Modal
        visible={previewVisible}
        title={false}
        footer={null}
        onCancel={() => {
          setPreviewVisible(false);
        }}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
}

export default Update;
