import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Select,
  Input,
  Button,
  DatePicker,
  Form,
  Collapse,
  Upload,
  message,
  Modal,
} from "antd";

import JoditEditor from "jodit-react";
import admin from "../../utils/const/api";

const { TextArea } = Input;
const { Panel } = Collapse;

const { Option } = Select;
function Update({ id,setVisibleModal,getPostList }) {
  const mainUrl = "admin/catalogs";
  const [form] = Form.useForm();
  const [data,setData]=useState([])
  const catalog_types = [
    { id: 1, name: "Leadership" },
    { id: 2, name: "Works" },
    { id: 3, name: "Vacancies" },
    { id: 4, name: "News" },
  ];
  let isErrorMessageShown = false;
  useEffect(() => {
    form.resetFields();
    if (id) {
      let obj = {};

      admin.get(`${mainUrl}/${id}`).then((res) => {
        console.log(res, "resss");

        res?.data?.data?.locales.forEach((d) => {
          obj[`name_${d.locale}`] = d.name;
        });
        obj['type'] = res?.data?.data?.type

        if (form.current !== null) {
          form.setFieldsValue(obj);
        }
      });
    }
  }, [id]);

  const langs = [
    {
      name: "Azərbaycan dili",
      key: "az",
      id: 1,
    },
    {
      name: "İngilis dili",
      key: "en",
      id: 2,
    },
    {
      name: "Rus dili",
      key: "ru",
      id: 3,
    },
  ];
  const savePosition = async (values) => {
    const langs = ["az", "en","ru"];
    let obj = {
      locales: langs.map((lang, index) => {
            return { locale: lang, name: values[`name_${lang}`]};
        }),
    };
    obj['type'] = values['type']
   
    checkUndefinedValues(values);
    if (!id) {
     
        await admin
            .post(mainUrl, obj)
            .then((res) => {
                // notify("", true);
                // getPositions();
                // cancelEditing();
                message.success("Item was successfully saved");
                setVisibleModal(false)
                getPostList()
            })
            .catch((err) => {
                
            });
    } else {
      
        await admin
            .put(`${mainUrl}/${id}`, obj)
            .then((res) => {
                // notify("", true);
                // getPositions();
                // cancelEditing();
                message.success("Item was successfully saved");
                setVisibleModal(false)
                getPostList()
            })
            .catch((err) => {
               
            });
    }
};


function checkUndefinedValues(obj) {
  const fields= Object.values(obj)
  const fieldsUndefined = fields.some(value => value ===null || value === '' || value=== undefined);
  if (fieldsUndefined) {
    message.error("Please fill in all fields.");
  } 
console.log(fields)
 
}


console.log(data,"daaa")
  return (
    <div>
      <Form form={form} onFinish={savePosition}>
        <div>
          <Row>
            <Col xs={6}>
              <p className={"mb-15"}>Kataloq</p>
              <Form.Item name={"type"} validateTrigger="onChange" onFinsh={savePosition }>
                <Select
                  showSearch
                  className="mb-4"
                  notFoundContent={null}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                >
                  {catalog_types.map((type, i) => {
                    return (
                      <Option key={i} value={type.id}>
                        {type.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Collapse defaultActiveKey={[1]}>
              {langs.map((l, index) => (
                <Panel header={l.name} key={l.id}  forceRender={true}>
                  <Col md={6} sm={6} xs={24}>
                    <p className={"mb-15"}>Adı</p>

                    <div key={l.id} className="form-lang">
                      <Form.Item
                        validateTrigger="onChange"
                        name={`name_${l.key}`}
                        // rules={[noWhitespace(t("inputError"))]}
                      >
                        <Input className="w-100" />
                      </Form.Item>
                      <span className="input-lang ">{l.key}</span>
                    </div>
                  </Col>
                </Panel>
              ))}
            </Collapse>

            <Col span={24}>
              <Button
                className="fr mt-3"
                htmlType="submit"
                type="primary"
                // onClick={onSubmit}
              >
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default Update;
