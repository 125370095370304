import { Button, Table, Tooltip, Popconfirm, Modal,message } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setTitle } from "../../redux/actions";
import admin from "../../utils/const/api";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { convertColumns } from "../../utils/columnconverter";
import Update from "./update";

const News = (props) => {
  const mainUrl = "admin/news";
  const [postlist, setPostList] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [id,setId]=useState(null)

  const [spin, setSpin] = useState(false);



  useEffect(() => {
    getPostList();
    props.setTitle("Xəbərlər");
  }, []);



  const cols = [
    { key: "title", con: true },
    { key: "short_description", con: true },
    { key: "background", con: true },
    { key: "id", con: true },
  ];

  const initialColumns = [
    {
      title: "Başlıq",
      dataIndex: "title",
      key: "1",
    },
    {
      title: "Qısa izah",
      dataIndex: "short_description",
      key: "2",
    },
    {
      title: "Arxa fon fotosu",
      dataIndex: "background",
      key: "3",
      render: (i) => {
        return <img className="table_image" alt="" src={i?.file} />;
      },
    },

    {
      title: "Əməliyyatlar",
      dataIndex: "id",
      key: "id",
      render: (i, element) => {
        return (
          <div className="flex flex-start">
            <Tooltip placement="topRight" className="ml-5" title={"Düzəliş et"}>
              <Button
                className="border-none mr-5 d-flex align-items-center"
                type="primary"
                onClick={()=>{
                  setVisibleModal(true) 
                  setId(i)}}
              >
                <EditOutlined  />
              </Button>
            </Tooltip>

            <Popconfirm
              placement="topRight"
              title={"Are you sure to delete this item?"}
              onConfirm={() => deleteList(i)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip placement="topRight" className="ml-5" title={"Sil"}>
                <Button
                  className="border-none d-flex align-items-center"
                  type="danger"
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const getPostList = (obj) => {
    setSpin(true);
    admin.get(mainUrl, { params: obj }).then((res) => {

      res.data?.data?.data && setSpin(false);
      setPostList(
        res.data?.data?.data.map((d, index) => {
          return {
            ...d,
            key: index + 1,
            index,
            title: d?.locale?.title,
            short_description: cleanHtmlTags(d?.locale?.short_description),
          };
        })
      );
    });
  };

  const deleteList = (i) => {
    if (i) {
      admin
        .delete(`${mainUrl}/${i}`)
        .then((res) => {
          message.success("Item was successfully deleted");
         getPostList()
        })
        .catch((err) => {
          // notify(err.response, false);
        });
    }
  };

  
  const cleanHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || "";
  };
  return (
    <>
      <Button
        className="add-btn"
        onClick={() => {
          setVisibleModal(true);
          setId(null)
        }}
      >
        Əlavə et
      </Button>

      <Table
        size="small"
        className="bg-white animated fadeIn"
        columns={initialColumns}
        dataSource={convertColumns(postlist, cols)}
        pagination={{
          pageSize: 25,
          current_page: 1,
        }}
      />

      <Modal
        centered
        className="addTaskModal padModal"
        visible={visibleModal}
        onOk={() => setVisibleModal(false)}
        onCancel={() => setVisibleModal(false)}
        footer={[]}
      >
        <Update
            id={id}
            setVisibleModal={setVisibleModal}
            getPostList={getPostList}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = ({ titleReducer }) => {
  return {
    titleReducer,
  };
};
export default connect(mapStateToProps, { setTitle })(News);
