import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Select,
  Input,
  Button,
  DatePicker,
  Form,
  Collapse,
  Upload,
  message,
  Modal,
} from "antd";

import JoditEditor from "jodit-react";
import admin from "../../utils/const/api";
import moment from "moment";

const { TextArea } = Input;
const { Panel } = Collapse;

const { Option } = Select;
function Update({ id, setVisibleModal, getPostList, catalogs, currencies }) {
  const mainUrl = "admin/vacancies";

  const [form] = Form.useForm();

  const editor = React.createRef();
  const [fileList, setFileList] = useState([]);
  const [media, setMedia] = useState();
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [about, setAbout] = useState({});

  const  gender= [
    { id: "m", name: "Kişi" },
    { id: "f", name: "Qadın" },
    { id: "nb", name: "Fərq etmir" },
  ]

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length <= 0) {
      setMedia(null);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  const uploadFile = ({ onSuccess, onError, file }) => {
    let form_data = new FormData();
    const filename = Math.random(1, 999999) + Date.now() + file.name;
    form_data.append("image", file, filename);
    admin
      .post("admin/file/uploads/single/image", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        // handleImage(res.data.data.image_uuid, form_key);
        setMedia(res?.data?.data?.image_uuid);
        onSuccess(null, file);
      })
      .catch((err) => onError());
  };

  const langs = [
    {
      name: "Azərbaycan dili",
      key: "az",
      id: 1,
    },
    {
      name: "İngilis dili",
      key: "en",
      id: 2,
    },
    {
      name: "Rus dili",
      key: "ru",
      id: 3,
    },
  ];
  useEffect(() => {
    form.resetFields();
    setFileList([]);
    if (id) {
      let obj = {};
      let datas = {};
      admin.get(`${mainUrl}/${id}`).then((res) => {
        setFileList(
          res?.data?.data?.file !== null
            ? [
                {
                  uid: res.data.data.file,
                  url: res.data.data.file.file,
                },
              ]
            : []
        );
        setMedia(res.data.data.file.uuid);
        res?.data?.data?.locales.forEach((d) => {
          obj[`title_${d.locale}`] = d.title;
          obj[`level_${d.locale}`] = d.level;
          obj[`experience_${d.locale}`] = d.experience;        
          obj[`qualification_${d.locale}`] = d.qualification;
          obj[`description_${d.locale}`] = d.short_description;
          obj[`requirements_${d.locale}`] = d.requirements;
          datas[`requirements_${d.locale}`] = d.requirements;
        });
        obj[`catalog_id`] = res.data.data.catalog_id;
        obj[`currency_id`] = res.data.data.currency_id; 
        obj[`gender`] = res.data.data.gender; 
        obj[`salary`]=res?.data?.data?.salary
        obj[`expire_date`]=moment(res.data.data.expire_date)

        setAbout(datas);
        if (form.current !== null) {
          form.setFieldsValue(obj);
        }
      });
    }
  }, [id]);

  const savePosition = async (values) => {
    const langs = ["az", "en", "ru"];
    let obj = {
      locales: langs.map((lang, index) => {
        return {
          locale: lang,
          title: values[`title_${lang}`],
          qualification: values[`qualification_${lang}`],
          level: values[`level_${lang}`],
          experience: values[`experience_${lang}`],
          short_description: values[`description_${lang}`],
          requirements:values[`requirements_${lang}`]
        };
      }),

      icon_uuid: media,
      catalog_id: values[`catalog_id`],
      currency_id:values[`currency_id`],
      gender:values.gender,
      expire_date: moment(values[`expire_date`]).format("YYYY-MM-DD"),
      salary:values.salary
    };

   
    checkUndefinedValues(values)   
     if (!id) {
      await admin
        .post(mainUrl, obj)
        .then((res) => {
          message.success("Item was successfully saved");
          setVisibleModal(false);
          getPostList();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await admin
        .put(`${mainUrl}/${id}`, obj)
        .then((res) => {
          message.success("Item was successfully saved");
          setVisibleModal(false);
          getPostList();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  async function checkUndefinedValues(obj) {
    const fields = Object.values(obj);
    const fieldsUndefined = fields.some(
      (value) => value === null || value === "" || value === undefined || media===null
    );
    if (fieldsUndefined) {
      message.error("Please fill in all fields.");
    }
    console.log(fields);
  }

  return (
    <div>
      <Form form={form} onFinish={savePosition}>
        <div>
          <Row>
            <div className="flex gap-2">
              <Col xs={4}>
                <p className={"mb-15"}>Foto</p>
                <Form.Item validateTrigger="onChange" required>
                  <Upload
                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    beforeUpload={beforeUpload}
                    customRequest={uploadFile}
                    onPreview={handlePreview}
                  >
                    {fileList.length < 1 && "+ Yüklə"}
                  </Upload>
                </Form.Item>
              </Col>

              <Col xs={6}>
                <p className={"mb-15"}>Kataloq</p>
                <Form.Item
                  name={"catalog_id"}
                  validateTrigger="onChange"
                  onFinsh={savePosition}
                >
                  <Select
                    showSearch
                    className="mb-4"
                    notFoundContent={null}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {catalogs.map((item, i) => {
                      return (
                        <Option key={i} value={item.id}>
                          {item.locale.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={6}>
                <p className={"mb-15"}>Valyuta</p>
                <Form.Item
                  name={"currency_id"}
                  validateTrigger="onChange"
                  onFinsh={savePosition}
                >
                  <Select
                    showSearch
                    className="mb-4"
                    notFoundContent={null}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {currencies.map((item, i) => {
                      return (
                        <Option key={i} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col  xs={6}>
                <p className={"mb-15"}>Maaş</p>

                <Form.Item
                  validateTrigger="onChange"
                  name={`salary`}
                  // rules={[noWhitespace(t("inputError"))]}
                >
                  <Input  />
                </Form.Item>
              </Col>

              <Col xs={6}>
                <p className={"mb-15"}>Cins</p>
                <Form.Item
                  name={"gender"}
                  validateTrigger="onChange"
                  onFinsh={savePosition}
                >
                  <Select
                    showSearch
                    className="mb-4"
                    notFoundContent={null}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {gender.map((item, i) => {
                      return (
                        <Option key={i} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col  xs={6}>
                <p className={"mb-15"}>Son tarix</p>

                <Form.Item
                  validateTrigger="onChange"
                  name={`expire_date`}
                  // rules={[noWhitespace(t("inputError"))]}
                >
              <DatePicker format={"YYYY-MM-DD"} />
                </Form.Item>
              </Col>
            </div>

            <Collapse defaultActiveKey={[1]}>
              {langs.map((l, index) => (
                <Panel header={l.name} key={l.id} forceRender={true}>
                     <div className="flex gap-1">
               
                    <Col md={6} sm={12} xs={16}>
                      <p className={"mb-15"}>Başlıq</p>

                      <div key={l.id} className="form-lang">
                        <Form.Item
                          validateTrigger="onChange"
                          name={`title_${l.key}`}
                          // rules={[noWhitespace(t("inputError"))]}
                        >
                          <Input className="w-100" />
                        </Form.Item>
                        <span className="input-lang ">{l.key}</span>
                      </div>
                    </Col>

                    <Col md={6} sm={12} xs={16}>
                      <p className={"mb-15"}>Tələb olunan təcrübə</p>

                      <div key={l.id} className="form-lang">
                        <Form.Item
                          validateTrigger="onChange"
                          name={`experience_${l.key}`}
                          // rules={[noWhitespace(t("inputError"))]}
                        >
                          <Input className="w-100" />
                        </Form.Item>
                        <span className="input-lang ">{l.key}</span>
                      </div>
                    </Col>

                    <Col md={6} sm={12} xs={16}>
                      <p className={"mb-15"}>İxtisaslaşma</p>

                      <div key={l.id} className="form-lang">
                        <Form.Item
                          validateTrigger="onChange"
                          name={`qualification_${l.key}`}
                          // rules={[noWhitespace(t("inputError"))]}
                        >
                          <Input className="w-100" />
                        </Form.Item>
                        <span className="input-lang ">{l.key}</span>
                      </div>
                    </Col>
                    <Col md={6} sm={12} xs={16}>
                      <p className={"mb-15"}>İxtisaslaşma səviyyəsi</p>

                      <div key={l.id} className="form-lang">
                        <Form.Item
                          validateTrigger="onChange"
                          name={`level_${l.key}`}
                          // rules={[noWhitespace(t("inputError"))]}
                        >
                          <Input className="w-100" />
                        </Form.Item>
                        <span className="input-lang ">{l.key}</span>
                      </div>
                    </Col>
                  </div>

                  <Col >
                      <p className={"mb-15"}>Qısa izah</p>

                      <div key={l.id} className="form-lang">
                        <Form.Item
                          validateTrigger="onChange"
                          name={`description_${l.key}`}
                          // rules={[noWhitespace(t("inputError"))]}
                        >
                          <Input className="w-100" />
                        </Form.Item>
                        <span className="input-lang ">{l.key}</span>
                      </div>
                    </Col>

                  <Col xs={24}>
                    <div key={l.id} className="form-lang">
                      <div className="mb-10">Tələblər</div>
                      <Form.Item
                        name={`requirements_${l.key}`}
                        validateTrigger="onChange"
                        // getValueFromEvent={(event, editor) => {
                        //     const dat = editor.getData();
                        //     return dat;
                        // }}
                        // rules={[noWhitespace(t("inputError"))]}
                      >
                        <JoditEditor
                          ref={editor}
                          //   value={this.state.aboutt[`about_${l.key}`]}
                          value={about[`requirements_${l.key}`]}
                          //   tabIndex={1} // tabIndex of textarea
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Panel>
              ))}
            </Collapse>

            <Col span={24}>
              <Button
                className="fr mt-3"
                htmlType="submit"
                type="primary"
                // onClick={onSubmit}
              >
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      <Modal
        visible={previewVisible}
        title={false}
        footer={null}
        onCancel={() => {
          setPreviewVisible(false);
        }}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
}

export default Update;
