import { Button, Table, Tooltip, Popconfirm, Modal,message } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setTitle } from "../../redux/actions";
import admin from "../../utils/const/api";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { convertColumns } from "../../utils/columnconverter";
import Update from "./update";

const Team = (props) => {
  const mainUrl = "admin/our/teams";
  const catalogUrl = "admin/catalogs?per_page=631342";
  const [postlist, setPostList] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [catalogs, setCatalogs] = useState([]);
  const [id, setId] = useState(null);

  const [spin, setSpin] = useState(false);

  useEffect(() => {
    getPostList();
    catalogList();
    props.setTitle("Komanda");
  }, []);

  const cols = [
    { key: "name", con: true },
    { key: "description", con: true },
    { key: "file", con: true },
    { key: "id", con: true },
  ];

  const initialColumns = [
    {
      title: "Adı",
      dataIndex: "name",
      key: "1",
    },

    {
      title: "Qısa izah",
      dataIndex: "description",
      key: "3",
      
   
    },
    {
      title: "Foto",
      dataIndex: "file",
      key: "4",
      render: (i) => {
        return <img className="table_image" alt="" src={i?.file} />;
      },
    },

    {
      title: "Əməliyyatlar",
      dataIndex: "id",
      key: "id",
      render: (i, element) => {
        return (
          <div className="flex flex-start">
            <Tooltip placement="topRight" className="ml-5" title={"Düzəliş et"}>
              <Button
                className="border-none mr-5 d-flex align-items-center"
                type="primary"
                onClick={() => {
                  setVisibleModal(true);
                  setId(i);
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>

            <Popconfirm
              placement="topRight"
              title={"Are you sure to delete this item?"}
              onConfirm={() => deleteList(i)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip placement="topRight" className="ml-5" title={"Sil"}>
                <Button
                  className="border-none d-flex align-items-center"
                  type="danger"
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const getPostList = (obj) => {
    setSpin(true);
    admin.get(mainUrl).then((res) => {
      console.log(res?.data);

      res.data?.data && setSpin(false);
      setPostList(
        res.data?.data.map((d, index) => {
          return {
            ...d,
            key: index + 1,
            index,
            name: d?.locale?.name,
            title: d?.locale?.title,
            description: cleanHtmlTags(d?.locale?.description),
          };
        })
      );
    });
  };

  const cleanHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || "";
  };
  const deleteList = (i) => {
    if (i) {
      admin
        .delete(`${mainUrl}/${i}`)
        .then((res) => {
          message.success("Item was successfully deleted");
          getPostList();
        })
        .catch((err) => {
          // notify(err.response, false);
        });
    }
  };

  const catalogList = () => {
    admin.get(catalogUrl).then((res) => {
      setCatalogs(res.data?.data?.data);
    });
  };

  return (
    <>
      <Button
        className="add-btn"
        onClick={() => {
          setVisibleModal(true);
          setId(null);
        }}
      >
        Əlavə et
      </Button>

      <Table
        size="small"
        className="bg-white animated fadeIn"
        columns={initialColumns}
        dataSource={convertColumns(postlist, cols)}
        pagination={{
          pageSize: 25,
          current_page: 1,
        }}
      />

      <Modal
        centered
        className="addTaskModal padModal"
        visible={visibleModal}
        onOk={() => setVisibleModal(false)}
        onCancel={() => setVisibleModal(false)}
        footer={[]}
      >
        <Update
          id={id}
          setVisibleModal={setVisibleModal}
          getPostList={getPostList}
          catalogs={catalogs}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = ({ titleReducer }) => {
  return {
    titleReducer,
  };
};
export default connect(mapStateToProps, { setTitle })(Team);
